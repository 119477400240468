import React from 'react';

import { Layout } from '../components';

export default function NotFound() {

    return(
        <Layout>
            <h2>404 Not Found</h2>
        </Layout>
    );
}